import React from 'react';
import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import { navigate } from '@reach/router';
import HeadBase from '../components/molecules/HeadBase';

const NotFoundPage = () => {
  // 404の場合はトップページに移動する
  setTimeout(() => {
    navigate('/');
  }, 0);
  return (
    <BaseLayoutTemplate>
      <HeadBase noindex />
      {/* <p>ページが見つかりませんでした。トップページへ移動します</p> */}
    </BaseLayoutTemplate>
  );
};

export default NotFoundPage;
